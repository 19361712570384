import BaseService from './BaseService';

const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            BaseService(param)
                .then(response => {
                    console.log('@api res....', response);
                    if (response.data.success) {
                        resolve(response.data);
                    } else {
                        if (response.data.error?.code === 401) {
                        }
                        reject(response.data.error.message);
                    }
                })
                .catch(errors => {
                    console.log('@api err....', errors);
                    reject(errors);
                });
        });
    },
};

export default ApiService;
